#form1{
    width: 80%;
    height: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    min-height: 40%;
    margin-bottom: 5%;
}

#inputs{
    border: none;
    min-height: 5%;
    min-width: 60%;
    font-family: "poppins-medium", sans-serif;
}

#fullName{
    height: 40px;
    width: 50%;
}
#email{
    height: 40px;
    margin-top: 2%;
    width: 50%;
}

#messages{
    height: 100%;
    margin-top: 2%;
    width: 50%;
    min-height: 40%;
    min-width: 50%;
    max-width: 50%;
    max-height: 5%;

}
#send{
    width: 50%;
    margin-top: 1%;
    background-color: red;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    overflow: auto;
    font-family: "poppins-medium", sans-serif;
    border: 2px solid red;
    color: white;
    cursor: pointer;
}
#subject{
    height: 40px;
    margin-top: 2%;
    width: 50%;
}


@media screen and (max-width: 922px) {
    #form1{
        width: 90%;
        height: 5%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        min-height: 40%;
        margin-bottom: 5%;
    }
}
@media screen and (max-width: 769px) {
    #form1{
        width: 100%;
    }
}
@media screen and (max-width: 426px) {
    #form1{
        width: 100%;
    }
}