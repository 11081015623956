#section1{
    height: 100%;
    background-image: url("./banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(1);
}

.Info{
    display: block;
    text-align: center;
    padding-top: 5%;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Info #p1{
    color:red;
    font-size: 30px;
    line-height: 0.5in;
    font-family: "poppins-medium", sans-serif;
}
.Info #p2{
    color: white;
    font-size: 50px;
    line-height: 0.2in;
    font-family: "poppins-medium", sans-serif;
}
.Info #p3{
    display: block;
    color: rgb(255, 255, 255);
    font-size: 150%;
    line-height: 0.1in;
    font-weight: normal;
   
}
@media screen and (max-width:320px) {
    .Info #p1{
        font-size: 13px
    }
    .Info #p2{
        font-size: 15px
    }
    .Info #p3{
       font-size: 10px;
       
    }
}
@media screen and (max-width: 700px) {
    .Info #p2{
        font-size: 2rem;
     }
     .Info #p3{
        font-size: 1rem;
     }
}
@media screen and (max-width: 500px) {
    .Info #p1{
       font-size: 1.5rem;
       line-height: 0.2in;
       padding-top: 10%;
    }
    .Info #p2{
        font-size: 2rem;
     }
     .Info #p3{
        font-size: 1rem;
     }
}
@media screen and (max-width: 375px) {
    .Info #p1{
       font-size: 1.5rem;
       line-height: 0.2in;
       padding-top: 10%;
    }
    .Info #p2{
        font-size: 2rem;
     }
     .Info #p3{
        font-size: 0.9rem;
     }
}
@media screen and (max-width: 360px) {
     .Info #p3{
        font-size: 0.8rem;
     }
}
