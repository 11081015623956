#section4{
    background-color: black;
    height: 100%;
    width: 100%;
    font-family: "poppins-medium", sans-serif;
    overflow-x:hidden ;
}

#section4 h4{
    color: red;
}

#section4 h1{
    color:white;
    font-size: 24px;
}

#section4 h5{
    color: grey;
}
#paragraphs2{
    padding-top: 2%;
    text-align: center;
}
#connections{
    text-align: center;
    margin-top: 5%;
}

#copyright{
    color: grey;
    text-align: center;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 5%;
    padding-bottom: 1%;
}
@media screen and (max-width:320px) {
    #section4 h4{
        color: red;
    }
    
    #section4 h1{
        color:white;
    }
    
    #section4 h5{
        color: grey;
    }
    #paragraphs2{
        padding-top: 2%;
        text-align: center;
        font-family: "poppins-medium", sans-serif;
    }
}



