.Technologies{
    display: block;
    width: 100%;
    font-size: 16px;
    list-style: none;
    margin-top: 5%;
}
.Technologies #mains{
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    
}
.techLogo{
    margin-top: 2.5%;
}

.TechnologyList{
    padding-left: 1%;
    padding-right: 1%;
    list-style: none;
    text-align: center;
    margin-top: 4%;
    font-family: "poppins-medium", sans-serif;
}
.Technologies span{
    font-family: "poppins-medium", sans-serif;
    letter-spacing: 3px;
}
.Technologies li{
    font-size: 16px;
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .Technologies{
        margin-top: 10%;
    }
}