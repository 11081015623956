#section3{
    background-color:white;
    height: 100%;
    width: 100%;
    overflow-x:hidden;
}

#paragraphs1{
    text-align: center;
    font-family: "poppins-medium", sans-serif;
    padding: 0%;
}

#paragraphs1 h4{
    color: red;
    letter-spacing: 5px;
    font-weight: bolder;
}
#paragraphs1 h5{
    color: grey;
}

#paragraphs1 h1{
    color: black;
    font-size: 24px;
}

.webProjects{
    display:flex;
    justify-content: center;
}
.mobileProjects{
    display: flex;
    justify-content: center;
}
@media screen and (max-width:320px) {
    #paragraphs1 h4{
        color: red;
        font-size: 13px;
    }
    
    #paragraphs1 h1{
        
        font-size: 15px;
    }
    
    #paragraphs1 h5{
        color: grey;
        font-size: 10px;
    }
    #paragraphs2{
        padding-top: 2%;
        text-align: center;
        font-family: "poppins-medium", sans-serif;
    }
    #copyright{
        font-size: 10px;
    }
}
