.Nav{
   position: fixed;
   top: 0%;
   background-color: black;
   width: 180px;
   height: 50px;
   color: white;
   margin-left: 20%;
   font-family: "poppins-medium", sans-serif;
   display: flex;
   justify-content: space-between;
   justify-content: center;
   align-items: center;
   border-radius: 5px;
   z-index: 2;
}
.Name{
   padding-left: 15%;
   letter-spacing: 2px;
   padding-top: 2%;
}
.Name li{
   color: red;
   margin-top: -15%;
   font-weight: bold;
   
}
.DropDown{
   position: absolute;
   top: 100%;
   width: 180px;
   background-color: black;
   border-radius: 5px;
}
.NavList{
   list-style: none;
   text-decoration: none;
}
.NavList li{
   text-decoration: none;
   padding: 10%;
   transition-duration: 300ms;
}
.NavList li:hover{
   transition-duration: 500ms;
   margin-left: 10%;
   cursor: pointer;
   color: red;
   font-weight: bold;
}
#s1{
   text-decoration: none;
   color: white;
   list-style: none;
}
@media screen and (max-width: 500px) {
   .Nav{
      position: fixed;
      left: -20%;
      border-radius: 0px  3px 5px 0px;
   }
   .DropDown{
      position: absolute;
      top: 100%;
      width: 180px;
      background-color: black;
      border-radius: 0px  3px 5px 0px;
   }
}

