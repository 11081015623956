#section2{
    height: fit-content;
    font-family: "poppins-medium", sans-serif;
    background-color: rgb(183, 184, 184);
    padding-top: 1%;
}
.introduction{
    text-align: center;
}


.introduction p{
    padding-left: 30%;
    padding-right: 30%;
    text-align: center;
    font-size: 16px;
}
.introduction h1{
    font-family: "poppins-medium", sans-serif;
    font-size: 24px;
}

.introduction h4{
    color: red;
    letter-spacing: 5px;
    font-weight: bolder;
    font-family: "poppins-medium", sans-serif;
}
#containerSkills{
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: center;
    text-align: center;
}
#containerButton li{
    list-style: none;
    text-align: center;
}


@media screen  and (max-width: 1235px) {
    .introduction p{
        padding-left: 30%;
        padding-right: 30%;
        font-family: "poppins-medium", sans-serif;
    }

}
@media screen  and (max-width: 1100px) {
    .introduction p{
        font-family: "poppins-medium", sans-serif;
    }
    #containerButton{
        padding: 5%;
    }

}
@media screen  and (max-width: 900px) {
    .introduction h4{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction h1{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction p{
        font-family: "poppins-medium", sans-serif;
        padding-left: 20%;
        padding-right: 20%;
    }
    

}
@media screen and (max-width: 600px) {
    .introduction h4{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction h1{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction p{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction p{
        padding-left: 3%;
        padding-right: 3%;
        font-family: "poppins-medium", sans-serif;
    }
    #containerSkills{
        display: block;
    }
    #containerButton{
        display: block;
        justify-content: center;
        margin-right: 6%;
    }
}

@media screen and (max-width: 430px) {
    .introduction h4{
        
        font-family: "poppins-medium", sans-serif;
    }
    .introduction h1{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction p{
        font-family: "poppins-medium", sans-serif;
    }
    .introduction p{
        padding-left: 3%;
        padding-right: 3%;
        font-family: "poppins-medium", sans-serif;
    }
    #containerSkills{
        display: flex;
        flex-direction: column;
    }
    #containerButton{
        display: block;
        justify-content: center;
        margin-right: 6%;
    }
}
