.connect{
    position: relative;
    padding-top: 10%;
    letter-spacing: 10px;
    padding-bottom: 3%;
}
#linkedin{
    cursor: pointer;
    margin-right: 1%;
}
#github{
    cursor: pointer;
}

#linkedin:hover{
    background-color: grey;
    border-radius: 10px;
}
#github:hover{
   background-color: grey;
   border-radius: 40px;
}
