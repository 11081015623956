.Skills{
    display: block;
    width: 100%;
    font-size: 16px;
    font-family: "poppins-medium", sans-serif;
    margin-top: 5%;
}
.list{
    margin-top: 5%;
}

.Skills p{
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
}
.SkillList{
    padding-left: 10%;
    padding-right: 10%;
    list-style: none;
    text-align: center;
    font-family: "poppins-medium", sans-serif;
}
.SkillList li{
    margin-top: 5%;
    margin-bottom: 5%;
}



.Skills span{
    font-family: "poppins-medium", sans-serif;
    letter-spacing: 3px;
}
@media screen and (max-width: 700px) {
    .Skills{
            margin-top: 15%;
    }
}