.HireMe{
    border: 3px solid black;
    background-color: black;
    width: 60%;
    height: 50px;
    font-family: "poppins-medium", sans-serif;
    background-color: transparent;
}
a{
  text-decoration: none;
}

.HireMe span{
    font-size: small;
    letter-spacing: 2px;
    color: red;
}

.HireMe:hover{
    border: 3px solid red;
    cursor: pointer;
}




